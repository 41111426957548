import React from "react";
import Seo from "@components/common/Seo";
import { Link } from "gatsby";
import { AiOutlineShopping } from "react-icons/ai";
import Logo from "@components/common/Logo";
import Title from "@components/common/Title";
import Text from "@components/common/Text";
import Image from "@components/common/CloudinaryImage";
import { IoCheckmarkDoneCircleOutline } from "react-icons/io5";
import { useStaticQuery, graphql } from "gatsby";

const Component = ({ location }) => {
    const data = useStaticQuery(graphql`
        query {
            currentBuildDate {
                currentDate
            }
        }
    `);

    if (!location?.state) {
        return null;
    }

    const {
        result,
        item: {
            title,
            sku,
            images: [image],
            offer,
            price,
            offer_text,
        },
        userDetails,
    } = location?.state;
    const {
        id,
        transactions: [{ amount }],
    } = result;

    return (
        <>
            <Seo title={`¡Compra realizada satisfactoriamente!`} />

            <div className="font-poppins">
                <header className="px-5 flex justify-between items-center border-b py-3">
                    <div>
                        <Link
                            to="/"
                            title="Volver al inicio"
                            className="flex items-center text-gray-600 hover:text-gray-800 duration-300"
                        >
                            <Logo className="duration-300 w-24 md:w-28" />
                        </Link>
                    </div>

                    <div>
                        <Link
                            to="/tienda"
                            title="Volver a la tienda"
                            className="flex items-center text-gray-600 hover:text-gray-800 duration-300"
                        >
                            <AiOutlineShopping className="w-8 h-8" />
                        </Link>
                    </div>
                </header>

                <main className="p-5">
                    <section className="max-w-4xl mx-auto">
                        <div className="flex flex-col items-center mt-5 border-b pb-10">
                            <IoCheckmarkDoneCircleOutline className="mx-auto text-green-600 w-32 h-32 md:w-48 md:h-48" />
                            <h1 className="text-green-600 text-3xl md:text-5xl font-light text-center mt-10">
                                ¡Compra realizada satisfactoriamente!
                            </h1>
                            <Text className="text-center text-lg md:text-xl mt-5">
                                ¡Muchas gracias por su confianza! En breve recibirá un correo
                                electrónico con los datos del pedido.
                            </Text>
                        </div>

                        <h2 className="mt-10 md:text-xl text-blue-700 text-center">
                            <strong className="font-semibold">Identificador de transacción:</strong>{" "}
                            {id}
                        </h2>

                        {/* Resumen */}
                        <div className="mt-5 border-b pb-16">
                            <Title className="mt-10 md:text-left text-center">
                                Resumen del pedido
                            </Title>

                            <table className="table table-striped md:mt-5 mx-auto text-xs sm:text-sm md:text-xl w-full">
                                <tbody>
                                    <tr className="border-b">
                                        <td className="col-span-2 uppercase text-gray-600 px-5 py-5">
                                            <div className="items-center flex gap-5">
                                                <Image
                                                    alt={title}
                                                    filename={image}
                                                    className=" flex-shrink-0 image w-32 h-32 object-center object-cover rounded shadow mt-1"
                                                />
                                                <div>
                                                    <h2 className="text-lg text-gray-700">
                                                        {title}
                                                    </h2>
                                                    <h3 className="text-xs">SKU: {sku}</h3>
                                                    {offer && (
                                                        <h4 className="text-orange-600 text-sm">
                                                            Oferta: {offer_text}{" "}
                                                            <span className="line-through">
                                                                {Number(offer).toFixed(2)} €
                                                            </span>{" "}
                                                            {Number(price).toFixed(2)} €
                                                        </h4>
                                                    )}
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr className="border-b">
                                        <td className="uppercase text-gray-600 px-5 py-3">
                                            Subtotal
                                        </td>
                                        <td className="text-right px-5 py-3 text-gray-800 whitespace-no-wrap">
                                            {Number(amount.details.subtotal).toFixed(2)} €
                                        </td>
                                    </tr>
                                    <tr className="border-b">
                                        <td className="uppercase text-gray-600 px-5 py-3">
                                            Impuestos
                                        </td>
                                        <td className="text-right px-5 py-3 text-gray-800 whitespace-no-wrap">
                                            {Number(amount.details.tax).toFixed(2)} €
                                        </td>
                                    </tr>
                                    <tr className="border-b">
                                        <td className="uppercase text-gray-600 px-5 py-3">Envío</td>
                                        <td className="text-right px-5 py-3 text-gray-800 whitespace-no-wrap">
                                            {Number(amount.details.shipping).toFixed(2)} €
                                        </td>
                                    </tr>
                                    <tr className="border-b">
                                        <td className="uppercase text-gray-600 px-5 py-3">
                                            Gastos de tramitación
                                        </td>
                                        <td className="text-right px-5 py-3 text-gray-800 whitespace-no-wrap">
                                            {Number(amount.details.discount).toFixed(2)} €
                                        </td>
                                    </tr>
                                    <tr className="border-b">
                                        <td className="uppercase text-gray-600 px-5 py-3">
                                            Descuento en envío
                                        </td>
                                        <td className="text-right px-5 py-3 text-gray-800 whitespace-no-wrap">
                                            {Number(amount.details.shipping_discount).toFixed(2)} €
                                        </td>
                                    </tr>
                                    <tr className="border-b">
                                        <td className="uppercase text-gray-600 px-5 py-3">
                                            Descuento
                                        </td>
                                        <td className="text-right px-5 py-3 text-gray-800 whitespace-no-wrap">
                                            {Number(amount.details.handling_fee).toFixed(2)} €
                                        </td>
                                    </tr>
                                    <tr className="border-b bg-gray-100">
                                        <td className="uppercase text-gray-600 px-5 py-3">Total</td>
                                        <td className="text-right px-5 py-3 text-gray-800 whitespace-no-wrap">
                                            {Number(amount.total).toFixed(2)} €
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div className="mt-5 pb-16">
                            <Title className="mt-10 md:text-left text-center">
                                Datos del comprador
                            </Title>
                            <table className="table table-striped md:mt-5 mx-auto text-xs sm:text-sm md:text-xl w-full">
                                <tbody>
                                    <tr className="border-b">
                                        <td className="uppercase text-gray-600 px-5 py-3">
                                            Nombre
                                        </td>
                                        <td className="text-right px-5 py-3 text-gray-800 whitespace-no-wrap">
                                            {userDetails.name} {userDetails.surname}
                                        </td>
                                    </tr>
                                    <tr className="border-b">
                                        <td className="uppercase text-gray-600 px-5 py-3">
                                            Teléfono
                                        </td>
                                        <td className="text-right px-5 py-3 text-gray-800 whitespace-no-wrap">
                                            {userDetails.phone}
                                        </td>
                                    </tr>
                                    <tr className="border-b">
                                        <td className="uppercase text-gray-600 px-5 py-3">
                                            Correo Electrónico
                                        </td>
                                        <td className="text-right px-5 py-3 text-gray-800 whitespace-no-wrap">
                                            {userDetails.email}
                                        </td>
                                    </tr>
                                    <tr className="border-b">
                                        <td className="uppercase text-gray-600 px-5 py-3">
                                            Dirección
                                        </td>
                                        <td className="text-right px-5 py-3 text-gray-800 whitespace-no-wrap">
                                            {userDetails.address}
                                        </td>
                                    </tr>
                                    <tr className="border-b">
                                        <td className="uppercase text-gray-600 px-5 py-3">
                                            Código Postal
                                        </td>
                                        <td className="text-right px-5 py-3 text-gray-800 whitespace-no-wrap">
                                            {userDetails.postalCode}
                                        </td>
                                    </tr>
                                    <tr className="border-b">
                                        <td className="uppercase text-gray-600 px-5 py-3">
                                            Municipio
                                        </td>
                                        <td className="text-right px-5 py-3 text-gray-800 whitespace-no-wrap">
                                            {userDetails.town}
                                        </td>
                                    </tr>
                                    <tr className="border-b">
                                        <td className="uppercase text-gray-600 px-5 py-3">
                                            Provincia
                                        </td>
                                        <td className="text-right px-5 py-3 text-gray-800 whitespace-no-wrap">
                                            {userDetails.province}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </section>
                </main>

                <footer className="bg-cindigo p-5 text-white flex items-center justify-between">
                    <div className="text-sm">
                        <p>
                            © iOpos Academia {new Date().getFullYear()}. Todos los derechos
                            reservados.
                        </p>
                        <p className="opacity-50 text-xs font-light italic">
                            Site desarrollado con GatsbyJS (build:{" "}
                            {data.currentBuildDate.currentDate}h)
                        </p>
                    </div>
                </footer>
            </div>
        </>
    );
};

export default Component;
